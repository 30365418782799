import _ from 'lodash';

import axios from 'axios';

// axios retry logic

let loaded = false;

/* eslint-disable no-underscore-dangle */
const defaultNumRetries = 3;
const defaultRetryDelay = 250;

function axiosRetry(err) {
  if (!err.config) {
    throw err;
  }
  const retryCount = err.config.__retryCount || 1;
  const status = err.response ? err.response.status : null;

  const disableRetry = err.config.disableRetry || false;
  const forceRetry = err.config.forceRetry || false;
  const numRetries = err.config.numRetries || defaultNumRetries;
  const retryDelay = err.config.retryDelay || defaultRetryDelay;
  // go ahead and retry on 503. Sim submit responsd quickly so it should be OK-ish to retry these when queue is down
  const is500 = status >= 500 && status <= 599;
  const isEmptyResponse = !err.response || err.code === 'ERR_NETWORK';
  const retryableMethod = _.includes(['get', 'head', 'options'], _.toLower(err.config.method));
  const shouldRetry = (retryableMethod || forceRetry) && !disableRetry && (is500 || isEmptyResponse);
  const retriesLeft = numRetries - retryCount;

  if (shouldRetry && retriesLeft > 0) {
    err.config.__retryCount = retryCount + 1; // eslint-disable-line no-param-reassign
    const delay = retryDelay * (retryCount ** 2);
    //return Promise.resolve(promiseDelay(delay)).then(() => axios(err.config));
    return new Promise(accept => { setTimeout(() => accept(axios(err.config)), delay); });
  }

  throw err;
}

if (!loaded) {
  /* eslint-enable no-underscore-dangle */
  axios.interceptors.response.use(undefined, axiosRetry);

  loaded = true;
}

export default axiosRetry;
