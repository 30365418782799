import _ from 'lodash';
import PropTypes from 'prop-types';

const If = props => {
  const {
    condition,
    children,
  } = props;
  const thenFunc = props.then;
  const elseFunc = props.else;

  let result;
  if (_.isFunction(condition)) {
    result = condition();
  } else {
    result = Boolean(condition);
  }

  if (result) {
    return thenFunc ? thenFunc() : children;
  }

  return elseFunc ? elseFunc() : null;
};

If.propTypes = {
  condition: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node,
  then: PropTypes.func,
  else: PropTypes.func,
};

If.defaultProps = {
  condition: null,
  children: null,
  then: null,
  else: null,
};

If.displayName = 'If';

export default If;
