export default {
  blue: '#00d9f7',
  darkblue: '#01616f',
  purple: '#7a76ee',
  green: '#00ff88',
  darkgreen: '#33937f',
  orange: '#ffbb33',
  midgray: '#777788',
  gray: '#333339',
  red: '#ff5050',

  info: '#fb3',
  iconInfo: '#00d9f7',
  success: '#0f8',
  warning: '#fb3',
  primary: '#66666a',
  secondary: '#00d9f7',
  error: '#ff5050',

  class: {
    deathknight: '#c41f3b',
    'death knight': '#c41f3b',
    demonhunter: '#a330c9',
    'demon hunter': '#a330c9',
    druid: '#ff7d0a',
    evoker: '#33937f',
    hunter: '#abd473',
    mage: '#69ccf0',
    monk: '#00ff96',
    priest: '#ffffff',
    paladin: '#f58cba',
    rogue: '#fff569',
    shaman: '#0070de',
    warlock: '#9482c9',
    warrior: '#c79c6e',
  },
  items: {
    0: '#9d9d9d',
    1: '#ffffff',
    2: '#1eff00',
    3: '#0070dd',
    4: '#a335ee',
    5: '#ff8000',
    6: '#e6cc80',
    7: '#00ccff',
    8: '#00ccff',
    72: '#e6cc80',
  },

  // 8.3 colors
  corruption: '#956dd1',
  purify: '#fff100',
};
