
import PropTypes from 'prop-types';
import React from 'react';
import Base from './Base';
import Menu from './Menu';
import config from './config';

/**
 * Absolutely positioned Menu component for use within Dropdown component
 */

const DropdownMenu = ({
  open,
  right,
  top,
  topExact,
  rightExact,
  leftExact,
  children,
  onDismiss,
  ...props
}, { rebass }) => {
  const { zIndex } = { ...config, ...rebass };

  let rootTop;
  let rootBottom;
  let rootRight;
  let rootLeft;
  if (top) {
    rootTop = 'auto';
    rootBottom = '100%';
  } else if (topExact) {
    rootTop = topExact;
    rootBottom = 'auto';
  } else {
    rootTop = '100%';
    rootBottom = 'auto';
  }

  if (right) {
    rootRight = 0;
    rootLeft = 'auto';
  } else if (rightExact) {
    rootRight = rightExact;
    rootLeft = 'auto';
  } else if (leftExact) {
    rootRight = 'auto';
    rootLeft = leftExact;
  } else {
    rootRight = 'auto';
    rootLeft = 0;
  }

  const sx = {
    root: {
      display: open ? null : 'none',
      position: 'absolute',
      left: rootLeft,
      right: rootRight,
      top: rootTop,
      bottom: rootBottom,
      zIndex: 4,
    },
    overlay: {
      position: 'fixed',
      display: open ? null : 'none',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    content: {
      position: 'relative',
      zIndex: zIndex[1],
    },
  };

  return (
    <Base
      {...props}
      className="DropdownMenu"
      baseStyle={sx.root}
    >
      <div
        style={sx.overlay}
        onClick={onDismiss}
      />
      <div style={sx.content}>
        <Menu
          {...props}
          children={children}
        />
      </div>
    </Base>
  );
};

DropdownMenu.propTypes = {
  /** Toggles visibility of DropdownMenu */
  open: PropTypes.bool,
  /** Anchors menu to the right */
  right: PropTypes.bool,
  /** Anchors menu to the top */
  top: PropTypes.bool,
  /** Click event callback for the background overlay */
  onDismiss: PropTypes.func,
};

DropdownMenu.defaultProps = {
  open: false,
  onDismiss() {},
};

DropdownMenu.contextTypes = {
  rebass: PropTypes.object,
};

export default DropdownMenu;

