/* eslint-env browser */
import _ from 'lodash';
import React from 'react';

import { isBetaHost } from 'shared/util';
import countries from 'shared/data/countries.json';

export const DEVMODE = process.env.NODE_ENV === 'development';

const log = (type, ...args) => {
  /* eslint-disable no-console */
  if (console && console[type]) {
    console[type](...args);
  }
  /* eslint-enable no-console */
};

export const dlog = (...args) => log('log', ...args);
export const dwarn = (...args) => log('warn', ...args);
export const derror = (...args) => log('error', ...args);

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const language = () => {
  let lang = 'en-US';

  if (navigator) {
    lang = (navigator.languages && navigator.languages[0])
      || navigator.language
      || navigator.userLanguage;
  }

  return lang;
};

export const moneyFormat = (amount, currency = 'usd', locale = null) => amount.toLocaleString(
  locale || language(),
  {
    style: 'currency',
    currency,
  },
);

export const centsFormat = (amountCents, currency, locale) => moneyFormat(amountCents / 100, currency, locale);

export const isBetaSite = () => {
  return isBetaHost(_.get(window, 'location.hostname'));
};

export const countryName = code => {
  const country = _.find(countries, { code });
  return country?.name;
};

export const useDebounce = (callback, delay) => {
  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debouncedCallback = (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
};
