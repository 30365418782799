/* eslint-env browser */
import PropTypes from 'prop-types';
import React from 'react';
import * as Sentry from '@sentry/browser';

import { Flex, Box } from 'reflexbox';
import { Text } from 'rebass';

import SharedConfig from 'shared/config';

import config from 'frontend/config';
import If from 'frontend/components/If';

import RaidbotsSkull from './RaidbotsSkull';

export const DefaultError = ({ title, description }) => {
  let displayTitle = title;
  let displayDescription = description;
  if (!displayTitle) {
    displayTitle = 'Something unexpectedly bad happened!';
    displayDescription = 'Seriallos forgot to write some error handling code and/or you found a bug.';
  }
  return (
    /* eslint-disable jsx-a11y/anchor-is-valid */
    <Flex
      mt={3}
      justify="center"
    >
      <Box
        lg={8}
        px={4}
        style={{
          color: config.colors.white,
          border: `2px solid ${config.colors.error}`,
          borderRadius: 10,
          fontFamily: config.fontFamily,
        }}
      >
        <Flex my={2}>
          <Box mr={2}>
            <RaidbotsSkull expression="dead" fill={config.colors.red} />
          </Box>
          <Box>
            <h2>
              {displayTitle}
            </h2>
          </Box>
        </Flex>
        <Box>
          <If
            condition={displayDescription}
            then={() => (
              <Box mb={2}>
                <Text bold>
                  {displayDescription}
                </Text>
              </Box>
            )}
          />
          <Text>
            General Troubleshooting:
          </Text>
          <ul>
            <li>
              <Text>
                Check your network connectivity
              </Text>
            </li>
            <li>
              <Text>
                <a href="#" onClick={() => window.location.reload(true)}>
                  Reload the page
                </a>
              </Text>
            </li>
            <li>
              <Text>
                Run through the
                {' '}
                <a href="https://support.raidbots.com/article/46-general-troubleshooting">
                  General Troubleshooting
                </a>
                {' '}
                support article
              </Text>
            </li>
            <li>
              <Text>
                Check Discord to see if the site is having issues
              </Text>
            </li>
            <li>
              <Text>
                If problems persist, please report the issue to Seriallos on
                {' '}
                <a href={SharedConfig.links.discord}>
                  Discord
                </a>
                {' '}
                or
                {' '}
                <a href={SharedConfig.links.support}>
                  email
                </a>
              </Text>
            </li>
          </ul>
        </Box>
      </Box>
    </Flex>
    /* eslint-enable jsx-a11y/anchor-is-valid */
  );
};

DefaultError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.element,
};

DefaultError.defaultProps = {
  title: null,
  description: null,
};

class DefaultErrorBoundary extends React.Component {
  static displayName = 'DefaultErrorBoundary';

  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    title: null,
    description: null,
  };

  state = {
    hasError: false,
    // showReset: false,
    title: null,
    description: null,
  };

  componentDidCatch(error) {
    let title;
    let description;
    let reportError = true;

    if (error.name === 'ChunkLoadError') {
      reportError = false;
      title = 'Network Connectivity Problem';
      description = 'Unable to load the website. Check your connectivity and/or reload the site';
    }

    this.setState({ hasError: true, title, description });

    if (reportError) {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <DefaultError
          title={this.state.title || this.props.title || undefined}
          description={this.state.description || this.props.description || undefined}
        />
      );
    }
    return this.props.children;
  }
}

export default DefaultErrorBoundary;
