
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Base from './Base';
import Label from './Label';
import Text from './Text';
import config from './config';

/**
 * Textarea form element with label
 */

const Textarea = ({
  label,
  name,
  message,
  hideLabel,
  children,
  style,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  ...props
}, { rebass }) => {
  const { scale, colors, borderColor } = { ...config, ...rebass };

  const invalid = props['aria-invalid'] || props.invalid;

  const rootProps = {
    style,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
  };

  const sx = {
    root: {
      marginBottom: scale[2],
      color: invalid ? colors.error : null,
    },
    textarea: {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      boxSizing: 'border-box',
      display: 'block',
      width: '100%',
      padding: scale[1],
      borderWidth: 1,
      borderStyles: 'solid',
      borderColor,
    },
  };

  const cx = classnames('Textarea', {
    isInvalid: invalid,
    isDisabled: props.disabled,
    isReadonly: props.readOnly,
  });

  return (
    <Base
      {...rootProps}
      className={cx}
      baseStyle={sx.root}
    >
      <Label
        htmlFor={name}
        hide={hideLabel}
        children={label}
      />
      <Base
        {...props}
        tagName="textarea"
        name={name}
        baseStyle={sx.textarea}
      />
      {message && <Text small children={message} />}
    </Base>
  );
};

Textarea.propTypes = {
  /** Label for form element */
  label: PropTypes.string.isRequired,
  /** Name attribute for form element */
  name: PropTypes.string.isRequired,
  /** Adds a helper or error message below the textarea */
  message: PropTypes.string,
  /** Hides the form element label */
  hideLabel: PropTypes.bool,
};

Textarea.defaultProps = {
  rounded: true,
};

Textarea.contextTypes = {
  rebass: PropTypes.object,
};

export default Textarea;

