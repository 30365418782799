
export const breakpoints = {
  sm: '(min-width: 40em)',
  md: '(min-width: 52em)',
  lg: '(min-width: 64em)'
}

const config = {
  breakpoints
}

export default config

