import { config } from 'rebass';
import colors from 'shared/colors';

const caps = {
  textTransform: 'uppercase',
  letterSpacing: '.2em',
};

export default {
  name: 'Dark',
  fontFamily: '"Avenir Next", Lato, Helvetica, sans-serif',
  color: '#eee',
  bold: 700,
  backgroundColor: '#111',
  borderRadius: 5,
  borderColor: `rgba(255, 255, 255, ${3 / 16})`,
  zIndex: [
    0,
    4,
    16,
    32,
    64,
  ],
  colors: {
    ...config.colors,
    ...colors,
  },
  inverted: '#222222',

  scale: [
    0, 10, 20, 40, 80,
  ],

  Card: {
    backgroundColor: '#222222',
    border: 0,
  },
  Panel: {
    borderWidth: 2,
    backgroundColor: '#000000',
  },
  NavItem: {
    fontSize: 12,
    color: '#ffbb33',
    ...caps,
  },
  Heading: {
    ...caps,
  },
  Button: {
    ...caps,
    fontSize: 12,
    color: '#ffbb33',
    opacity: 7 / 8,
  },
  ButtonOutline: {
    ...caps,
    fontSize: 12,
    color: '#ffbb33',
    opacity: 7 / 8,
  },
  Toolbar: {
    minHeight: 64,
    color: '#ffbb33',
    backgroundColor: `rgba(0, 0, 0, ${7 / 8})`,
  },
  Label: {
    opacity: 5 / 8,
  },
  Menu: {
    color: '#ffbb33',
    backgroundColor: '#000',
  },
  Message: {
    color: '#111111',
    opacity: 15 / 16,
  },
  Text: {
    opacity: 7 / 8,
  },
  Footer: {
    opacity: 1 / 2,
  },
};
